import { TIME_IN_FORCE_GTD } from "components/trade/constants.js";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import messages from "utils/validate.messages";
import validate, { isDefined } from "validate.js";

validate.validators.presence.options = { message: messages.blank };
validate.validators.restricted = () => messages.restricted;
validate.validators.email.options = { message: `^${messages.invalidFormat}` };

export const NAME_VALIDATION = {
  format: {
    pattern: /[A-Za-z \-.']+/,
    message: `^${messages.invalidFormat}. You can use only (  ), (-), (.) and (').`,
  },
  length: {
    minimum: 2,
    tooShort: "^Minimum value allowed %{count} symbols",
    maximum: 100,
    tooLong: "^Maximum value allowed %{count} symbols",
  },
};

export function minApprove(value, options = {}) {
  const { rules = [], minimum = 1, message = "is too weak" } = options;
  let acceptErrors = rules.length - minimum;

  const isValid =
    rules.reduce((acceptErrors, rule) => {
      return acceptErrors === -1 || !validate.single(value, rule)
        ? acceptErrors
        : acceptErrors - 1;
    }, acceptErrors) !== -1;

  return !isValid ? message : undefined;
}

validate.validators.minApprove = minApprove;

export const PASSWORD_RULES = [
  { format: /.*[a-z]+.*/ },
  { format: /.*[A-Z]+.*/ },
  { format: /.*[0-9]+.*/ },
  { format: /.*[~`!@#$%^&*()\-_+={}[\]|\\;:"<>,./?]+.*/ },
];

export function password(value = "", options = {}) {
  const { message = "^is week.", strong = false } = options;
  return value
    ? validate.single(
        value.trim(),
        {
          length: {
            minimum: 8,
            message,
          },
          minApprove: {
            rules: PASSWORD_RULES,
            minimum: strong ? 4 : 3,
            message,
          },
        },
        { format: "flat" },
      )
    : undefined;
}

validate.validators.password = password;

export const maxDecimalDigitsValidator = (value, options = {}) => {
  const { max = 2 } = options;
  const valueArray = value ? value.toString().split(".") : null;
  const isInvalid = valueArray && valueArray[1] && valueArray[1].length > max;
  return isInvalid ? `${messages.maxDigitsAfterDecimal}: ${max}` : undefined;
};

validate.validators.maxDecimalDigits = maxDecimalDigitsValidator;

validate.validators.expiryGoodTillDate = (
  value,
  options = {},
  key,
  attributes,
) => {
  const { timeInForce } = attributes;
  if (timeInForce === TIME_IN_FORCE_GTD && !value)
    return "required for Good Till Date.";
  return undefined;
};

export const customNumber = (value, options = {}) => {
  const num = parseFloat(value);
  const isValid = isNaN(num) || options.test(num);
  return isValid ? undefined : options.msg;
};

validate.validators.customNumber = customNumber;

validate.validators.array = (arrayItems = [], itemConstraints) => {
  const arrayItemErrors = arrayItems.reduce((errors, item, index) => {
    const error = validate(item, itemConstraints);
    errors[index] = error ? { ...error } : {};
    return errors;
  }, []);
  return isEmpty(arrayItemErrors) ? null : [...arrayItemErrors];
};

validate.validators.arrayFixed = (arrayItems = [], itemConstraints) => {
  const arrayItemErrors = arrayItems.reduce((errors, item, index) => {
    const error = validate(item, itemConstraints);
    if (error) errors[index] = error;
    return errors;
  }, []);
  return isEmpty(arrayItemErrors) ? undefined : [...arrayItemErrors];
};

validate.validators.itemComparator = (
  value,
  { comparator },
  attribute,
  attributes,
) => {
  if (!comparator) return undefined;
  const error = validate(attributes, { [attribute]: comparator(attributes) });
  return error ? error[attribute] : undefined;
};

const getCompareToFields = (compareTo) =>
  typeof compareTo === "string" ? [compareTo] : compareTo;

const getErrorMessage = (message, value, compareTo) => {
  const prefix = compareTo?.includes('today') ? 'date' : 'value';
  return compareTo
    ? `^Should be ${message} ${getCompareToFields(compareTo)?.join(", ")}'s ${prefix}`
    : `^Should be ${message} ${value}`;
};

const getValue = (formData) =>
  isNaN(formData) ? formData?.value || formData : parseFloat(formData);

const isNotNullOrUndefined = (value) =>
  value !== null && value !== undefined && !isNaN(value);

export const createComparisonValidator = (value, options, isDate = false) => {
  if (!options) return;

  const parsedValue = isDate && value ? moment(value) : Number(value);

  if (!parsedValue || (isDate && !parsedValue.isValid())) return;

  const checkCondition = (condition, message, compareTo) => {
    if (!condition) {
      return getErrorMessage(message, value, compareTo);
    }
  };

  const comparisonTypes = [
    {
      key: "greaterThanOrEqualTo",
      message: isDate ? "after or the same as" : "greater or equal to",
      operator: (v, target) => (isDate ? v.isSameOrAfter(target) : v >= target),
    },
    {
      key: "greaterThan",
      message: isDate ? "after" : "greater than",
      operator: (v, target) => (isDate ? v.isAfter(target) : v > target),
    },
    {
      key: "lessThanOrEqualTo",
      message: isDate ? "before or the same as" : "less than or equal to",
      operator: (v, target) =>
        isDate ? v.isSameOrBefore(target) : v <= target,
    },
    {
      key: "lessThan",
      message: isDate ? "before" : "less than",
      operator: (v, target) => (isDate ? v.isBefore(target) : v < target),
    },
    {
      key: "equalTo",
      message: isDate ? "the same as" : "equal to",
      operator: (v, target) => (isDate ? v.isSame(target) : v === target),
    },
  ];

  for (const { key, message, operator } of comparisonTypes) {
    if (isDefined(options[key])) {
      const { formData } = options;
      const formDataId = isDate && options[key].includes('today') ? undefined : options[key];
      const formValue = getValue(formData[formDataId]);
      let valueToCompare = formValue;
      if(isDate) {
        if(options[key].includes('today')) {
          valueToCompare = moment();
        } else if(formValue) {
          valueToCompare = moment(formValue);
        }
      }

      if (
        isNotNullOrUndefined(parsedValue) &&
        isNotNullOrUndefined(valueToCompare)
      ) {
        return checkCondition(
          operator(parsedValue, valueToCompare),
          message,
          options[key],
        );
      }
    }
  }
};

const comparisonValidator = (value, options) =>
  createComparisonValidator(value, options);
const dateComparisonValidator = (value, options) =>
  createComparisonValidator(value, options, true);

validate.validators.compareTo = comparisonValidator;
validate.validators.compareToDate = dateComparisonValidator;

const stringComparisonValidator = (value, options) => {
  const selectedValue = value?.value || value;
  return getErrorMessage("equal to", selectedValue, options);
};

validate.validators.stringComparison = stringComparisonValidator;

export const permittedValuesValidator = (value, permittedValues) => {
  const selectedValue = value?.label || value?.value || value;
  if (
    isDefined(selectedValue) &&
    permittedValues?.length &&
    !permittedValues.includes(selectedValue)
  ) {
    return `^Does not match permitted values: ${permittedValues.join(", ")}`;
  }
};

validate.validators.permittedValues = permittedValuesValidator;

export default validate;
