import gql from "graphql-tag";

export const QUERY_SP_FORM_DATA = gql`
  query getSpFormQuery($isInternalOnly: Boolean, $isVisibleOnly: Boolean) {
    getSpForm(isInternalOnly: $isInternalOnly, isVisibleOnly: $isVisibleOnly) {
      structureName
      payoffName
      payoffUri
      layout {
        id
        defaultValue
        component
        label
        col
        mandatory
        categorisations
        componentConfig
        compositeArray
        validation
        positionOptions {
          relatedField
          col
        }
      }
      errors
      rules
      fieldValidationRules
      onePager
      solveFor
    }
  }
`;

export const QUERY_CONFIGURABLE_SP_FORM_DATA = gql`
  query getConfigurableSpFormQuery(
    $isInternalOnly: Boolean
    $isVisibleOnly: Boolean
  ) {
    getConfigurableSpForm(
      isInternalOnly: $isInternalOnly
      isVisibleOnly: $isVisibleOnly
    ) {
      id,
      structureName
      payoffName
      payoffUri
      layout {
        id
        defaultValue
        component
        label
        col
        mandatory
        categorisations
        componentConfig
        compositeArray
        validation
        positionOptions {
          relatedField
          col
        }
        visible
        readOnly
      }
      errors
      rules
      fieldValidationRules
      onePager
    }
  }
`;

export const QUERY_SP_PRICE = gql`
  query getStructuredProductPriceQuery(
    $formValues: FormValuesType
    $underlyings: [UnderlyingsType]
    $schedule: FormValuesType
  ) {
    getStructuredProductPrice(
      formValues: $formValues
      underlyings: $underlyings
      schedule: $schedule
    ) {
      pricingInfo
      validationErrors
    }
  }
`;

export const QUERY_SP_PRICING_HISTORY = gql`
  query getStructuredProductPricingHistoryQuery {
    getStructuredProductPricingHistory {
      prices
    }
  }
`;

export const QUERY_SP_PRICING_FOR_TAG = gql`
  query getStructuredProductPricingTagQuery($tag: String!) {
    getStructuredProductPricingTag(tag: $tag) {
      price
    }
  }
`;

export const QUERY_CONFIGURABLE_SP_WRAPPER_FORM_DATA = gql`
  query getConfigurableSpWrapperFormQuery {
    getConfigurableSpWrapperForm {
      wrapperName
      wrapperUri
      layout {
        id
        defaultValue
        component
        label
        col
        mandatory
        categorisations
        componentConfig
        validation
        positionOptions {
          relatedField
          col
        }
        visible
        readOnly
      }
      errors
      rules
      fieldValidationRules
    }
  }
`;

export const QUERY_CONFIGURABLE_SP_OPTIONS = gql`
  query getConfigurableSpOptionsQuery($options: [CustomOptionsType]) {
    getConfigurableSpOptions(options: $options) {
      customOptions
    }
  }
`;

export const QUERY_CONFIGURABLE_SP_ISSUER_RESTRICTIONS = gql`
  query getIssuerSettingRestrictionsQuery($issuerId: String!) {
    getIssuerSettingRestrictions(issuerId: $issuerId) {
      issuerSettingsRestrictions
    }
  }
`;

export const QUERY_CONFIGURABLE_SP_ISSUERS = gql`
  query getIssuerForPayoffAndWrapperQuery($payoff: String!, $wrapper: String!) {
    getIssuerForPayoffAndWrapper(payoff: $payoff, wrapper: $wrapper) {
      issuers
    }
  }
`;

export const QUERY_ISSUER_PROGRAMME_SETTING = gql`
  query getIssuerProgrammeSettingQuery(
    $formValues: FormValuesType
    $underlyings: [UnderlyingsType]
  ) {
    getIssuerProgrammeSetting(
      formValues: $formValues
      underlyings: $underlyings
    ) {
      settings
      status
    }
  }
`;

export const QUERY_STATIC_DATA = gql`
  query getStaticDataQuery($staticData: String!) {
    getStaticData(staticData: $staticData) {
      value
      label
    }
  }
`;

export const QUERY_PROGRAMME_DATA = gql`
  query getProgrammesByPayoffWrapperQuery($payoff: String!, $wrapper: String!) {
    getProgrammesByPayoffWrapper(payoff: $payoff, wrapper: $wrapper) {
      programmes
    }
  }
`;

export const QUERY_SCHEDULE = gql`
  query getScheduleQuery(
    $scheduleParams: ScheduleInputType!
    $structure: String
    $wrapper: String
  ) {
    getSchedule(scheduleParams: $scheduleParams, structure: $structure, wrapper: $wrapper) {
      schedule
      config
    }
  }
`;

export const QUERY_SCHEDULE_CONFIG = gql`
  query getScheduleConfigQuery($structure: String, $uri: String, $wrapper: String) {
    getScheduleConfig(structure: $structure, uri: $uri, wrapper: $wrapper) {
      config
    }
  }
`;

export const QUERY_FLOATING_LEG_SCHEDULE = gql`
  query getFloatingLegScheduleQuery(
    $scheduleParams: FloatingLegScheduleInputType!
    $structure: String
    $wrapper: String
  ) {
    getFloatingLegSchedule(scheduleParams: $scheduleParams, structure: $structure, wrapper: $wrapper) {
      schedule
      config
    }
  }
`;

export const QUERY_FPT_DISTRIBUTORS = gql`
  query getDistributorsQuery($isOtc: Boolean, $isMf: Boolean) {
    getDistributors(isOtc: $isOtc, isMf: $isMf) {
      label
      value
      id
    }
  }
`;

export const VALIDATE_SCHEDULE = gql`
  query validateScheduleQuery($validationParams: ValidateScheduleInputType!) {
    validateSchedule(validationParams: $validationParams) {
      statusCode
      validationErrors
    }
  }
`;

export const VALIDATE_FLOATING_SCHEDULE = gql`
  query validateFloatingScheduleQuery($floatingValidationParams: ValidateFloatingScheduleInputType!) {
    validateFloatingSchedule(floatingValidationParams: $floatingValidationParams) {
      statusCode
      validationErrors
    }
  }
`;

export const QUERY_FPT_STRESS_TEST_RESULTS = gql`
  query getStressResultsQuery($tag: String!) {
    getStressResults(tag: $tag) {
      resultName
      resultValue
      resultPassFail
    }
  }
`;

export const QUERY_SUBMIT_TRADE = gql`
  mutation submitTradeQuery(
    $tag: String!
    $tradeFormValues: ConfigurableSpTradeFieldsType
  ) {
    submitTrade(tag: $tag, tradeFormValues: $tradeFormValues)
  }
`;

export const QUERY_IDENTIFIERS_DATA = gql`
  query getReservedIdentifiersQuery($isOtc: Boolean!, $issuer: String!) {
    getReservedIdentifiers(isOtc: $isOtc, issuer: $issuer) {
      isin
      valoren
      wkn
      commonCode
    }
  }
`;
